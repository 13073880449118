/***
 * @master
 */
const url = "https://node.chromeliquidity.org";
const user = "https://node.chromeliquidity.org/api/v1/user";
const withdraw = "https://node.chromeliquidity.org/api/v1";

/***
 * @UAT
 */
// const url = "https://nodepune-liquidity.mobiloitte.io";
// const user = "https://nodepune-liquidity.mobiloitte.io/api/v1/user";
// const withdraw = "https://nodepune-liquidity.mobiloitte.io/api/v1";

// const url = "http://172.16.1.53:1050";
// const user = "http://172.16.1.53:1050/api/v1/user";
// const withdraw = "http://172.16.1.53:1050/api/v1";

const ApiConfig = {
  //auth
  signup: `${url}/api/v1/user/register`,
  verifyOTP: `${url}/api/v1/user/verify`,
  resendOTP: `${url}/api/v1/user/resend`,
  login: `${url}/api/v1/user/login`,
  forgotPassword: `${url}/api/v1/user/forgotPassword`,
  resetPassword: `${url}/api/v1/user/resetPassword`,
  userNameExist: `${url}/api/v1/user/userNameExist`,

  //Top Bar
  listNotification: `${url}/api/v1/notification/listNotification`,

  //dashboard
  viewWallet: `${user}/viewWallet`,
  getFiveStarBonusData: `${user}/getFiveStarBonusData`,
  getRankAndRewards: `${user}/getRankAndRewards`,
  buyPlan: `${url}/api/v1/buy/buyPlan`,
  liquidityDetail: `${user}/earningAffiliates`,
  getDashboardDirectBinaryIncentive: `${user}/getDashboardDirectBinaryIncentive`,
  getLiquidityRewards: `${user}/getLiquidityRewards`,

  //settings
  viewMyProfile: `${user}/getProfile`,
  updateProfile: `${user}/updateProfile`,
  changePassword: `${user}/changePassword`,
  updatelegMode: `${user}/updatelegMode`,
  addchangeEmailRequest: `${user}/addchangeEmailRequest`,
  verifyOTPForEmailChangeReq: `${url}/api/v1/user/verifyOTPForEmailChange`,
  resendOTPForEmailChangeRequest: `${user}/resendOTPForEmailChangeRequest`,

  // get binary tree data
  getIndirectUsers: `${url}/api/v1/user/getBinaryTreeDetails`,
  getBinaryHistoryDetails: `${url}/api/v1/user/getBinaryHistoryDetails`,
  getUserByUsername: `${user}/getUserByUsername`,
  getStatisticsDetails: `${user}/getStatisticsDetails`,

  // referral
  referralHistory: `${url}/api/v1/user/referralHistory`,

  // KYC
  addKyc: `${url}/api/v1/kyc/addKyc`,
  addAadharCard: `${url}/api/v1/kyc/addAadharCard`,
  addDrivingLicence: `${url}/api/v1/kyc/addDrivingLicence`,
  addNationalId: `${url}/api/v1/kyc/addNationalId`,
  addPassport: `${url}/api/v1/kyc/addPassport`,
  addSelfie: `${url}/api/v1/kyc/addSelfie`,
  getUserKycDetails: `${url}/api/v1/kyc/getUserKycDetails`,

  //wallet
  withdrawHistory: `${user}/withdrawHistory`,
  verifyWithdrawRequestOTP: `${withdraw}/transaction/verifyWithdrawRequestOTP`,
  userWithdrawAmountLocked: `${withdraw}/transaction/userWithdrawAmountLocked`,
  verifyWithdrawRequestLockedOTP: `${withdraw}/transaction/verifyWithdrawRequestLockedOTP`,
  
  resendWithdrawRequestOTP: `${withdraw}/transaction/resendWithdrawRequestOTP`,
  getMinWithdrawalAmount: `${url}/api/v1/admin/getMinWithdrawalAmount`,
  //Static content
  getSingleStaticContent: `${url}/api/v1/static/static/TermsConditions`,

  //Transaction
  getWithdrawTransactionList: `${url}/api/v1/transaction/getWithdrawTransactionList`,

  //Report data
  getReportData: `${url}/api/v1/user/reportData`,

  //claim reward
  userWithdrawAmount: `${url}/api/v1/transaction/userWithdrawAmount`,

  //subscribe
  subscribe: `${url}/api/v1/subscriber/user-subscribe`,

  //Blog
  getblogMediaList: `${url}/api/v1/blogMedia/getblogMediaList`,

  // staring box liquidity dashboard api
  getLiqidityDashboard: `${url}/api/v1/dashboard/liquidity-dashboard`,
  sendPreviousEmailChange: `${url}/api/v1/user/sendOTPForEmailChangeRequest`,
  sendPreviousEmailChangeOtpVerify: `${url}/api/v1/user/verify`,
  newEmailChangeDetails: `${url}/api/v1/user/addchangeEmailRequest`,
};
export default ApiConfig;
